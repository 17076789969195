








































import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator'
import { Debounce } from 'lodash-decorators'
import { v4 as uuid } from 'uuid'
import * as Sentry from '@sentry/vue'

import Confirmation from '@/components/modals/Confirmation.vue'
import MentorRateTaskForm from '@/components/views/exercise/mentor/MentorRateTaskForm.vue'
import TaskEmpty from '@/components/TaskEmpty.vue'
import TextEditor from '@/components/_uikit/editor/TextEditor.vue'
import TiptapEditor from '@/components/_uikit/editor/TiptapEditor.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import {
  CourseType,
  EducationLargeTaskResource,
  ExerciseStatus,
  MasterLargeResource,
} from '@/store/types'
import AuthModule from '@/store/modules/auth'
import EditorModule from '@/store/modules/editor'
import MentorExercisesModule from '@/store/modules/mentor/exercises'
import { timeLimit } from '@/utils/constants'
import { formatDate } from '@/utils/functions'

const camelCaseKeys = require('camelcase-keys')
const { detectOS } = require('detect-browser')

@Component({
  components: {
    Confirmation,
    MentorRateTaskForm,
    TaskEmpty,
    TextEditor,
    TiptapEditor,
  },
})
export default class MentorPracticeTask extends Mixins(NotifyMixin) {
  @Ref() confirm!: Confirmation

  @Prop({ required: true })
  private task!: EducationLargeTaskResource

  @Prop({ required: true })
  private groupId!: number

  @Prop({ default: null })
  private master!: MasterLargeResource

  @Prop({ default: false })
  private readonly!: boolean

  @Prop({ default: null })
  private exerciseCourseType!: CourseType

  private content = this.task.content ? JSON.parse(this.task.content) : JSON.parse('""')

  // Есть ли соединение с интернетом
  private isOnline = navigator.onLine

  // Последнее значение контента
  // Нужно для сравнения текущего контента, с последним отправленным на бэк, чтобы не слать повторно одно и тоже
  private lastContentValue = this.content

  // Флаг для определения, было вызвано событие pagehide или нет
  private terminatingEventSent = false

  // Флаг отправки контента автосохранения
  private isLoadingContent = false
  private isSave = false

  // Флаг нахождения вкладки браузера в фокусе
  private isFocusedWindow = true

  // Флаг актуальности контента
  private isRelevantContent = true

  private get focusMenu() {
    return EditorModule.focusMenu
  }

  // Фокус в дополнительных меню редактора
  private get isFocusMenuEditor() {
    // fix sentry issue:
    // https://sn.atwinta.online/organizations/atwinta/issues/30041/?project=22&query=&referrer=project-issue-stream
    return this.focusMenu ? Object.values(this.focusMenu).includes(true) : false
  }

  private draftSaveAt = ''

  // Таймштамп потери интернета
  private timestampLossInternet = 0

  private subscriptionDraft: any = null
  private subscriptionTaskStatusUpdate: any = null

  private get isNewEditor() {
    if (this.task.content === '') return true
    return typeof JSON.parse(this.task.content) === 'string'
  }

  private get isStatusWait () {
    return this.task.exerciseStatus?.value === ExerciseStatus.WAIT || this.task.exerciseStatus?.value === ExerciseStatus.IN_PROCESS
  }

  private get isStatusChecked () {
    return this.task.status?.value === ExerciseStatus.CHECKED || this.task.status?.value === ExerciseStatus.ON_CHECK
  }

  private get isStatusComplete () {
    return this.task.exerciseStatus?.value === ExerciseStatus.COMPLETE
  }

  private get isStatusTaskComplete () {
    return this.task.status.value === ExerciseStatus.COMPLETE
  }

  private get isEmpty() {
    return Boolean(!this.content || this.content === '<p></p>')
  }

  private get hash() {
    return MentorExercisesModule.task?.draftHash
  }

  private get selfID() {
    if (AuthModule.self)
      return AuthModule.self.id
    return null
  }

  private get isFocusedEditor() {
    if (this.$refs.tiptap)
      return (this.$refs.tiptap as any).editor.isFocused || this.isFocusMenuEditor
    return false
  }

  // Определяем ОС для прослушки событий (десктоп|мобилка)
  private get isMobile() {
    return /iOS|Android OS|BlackBerry OS|Windows Mobile/.test(detectOS(navigator.userAgent))
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private timer?: number

  // Активность пользователя на вкладке
  private isActive = false

  // хеш устройства пользователя
  private deviceHash = uuid()

  // флаг о наличии более актуального контента
  // нужен, когда пользователь что-то пишет и в этот момент ему приходит контент по сокету
  private isUpdateContent = false

  // временныый контент
  private tempData = {
    content: '',
    dateAt: '',
  }

  private updateContentOnSocket(content: string, data: any) {
    this.lastContentValue = content
    this.content = content
    this.$bus.$emit('tiptapSetContent', this.content)
    MentorExercisesModule.setContentTask(data.content)
    MentorExercisesModule.setHashTask(data.hash)
  }

  private mounted () {
    MentorExercisesModule.setCurrentMasterID(+this.$route.params.masterID)
    this.resetTimer()
    window.addEventListener('online', this.connect)
    window.addEventListener('offline', this.disconnect)
    window.addEventListener('pagehide', this.handlePageHide)
    document.addEventListener('visibilitychange', this.handlePageHide)
    window.addEventListener('focus', this.handleWindowFocus)
    window.addEventListener('blur', this.handleWindowBlur)


    if (!(this.isStatusComplete || this.readonly)) {
      // Если вторая часть доступна для редактирования, тогда
      // включает отслеживание активности вкладки
      if (this.isMobile) {
        window.addEventListener('touchstart', this.resetTimer)
        window.addEventListener('touchmove', this.resetTimer)
        window.addEventListener('click', this.resetTimer)
        window.addEventListener('keydown', this.resetTimer)
      } else {
        window.addEventListener('mousemove', this.resetTimer)
        window.addEventListener('keydown', this.resetTimer)
        window.addEventListener('scroll', this.resetTimer)
        window.addEventListener('click', this.resetTimer)
      }

      this.subscriptionDraft = this.$centrifuge.newSubscription(`drafts.task.${this.selfID}.${this.task.uuid}.${this.master.user.id}`)
      this.subscriptionDraft.on('publication', ({ data }: any) => {
        if (data.event === 'App\\Events\\Broadcast\\DraftStoredEvent') {
          const content: string = JSON.parse(data.content)

          // Если пользователь закрыл модалку о расхождении контента, то он ничего не может писать в редакторе
          // чтобы он мог скопировать то, что написал, и это не перезатёрлось, если будет изменено в другом месте
          // нужно запретить обновление контента по сокетам
          if (this.isRelevantContent && this.deviceHash !== data.device_hash) {
            // Пришел сокет, но пользователь что-то пишет
            // запоминаем это, чтобы потом показать модалку
            if (this.isActive && this.isFocusedEditor) {
              Sentry.addBreadcrumb({
                category: 'message',
                level: 'info',
                message: 'Контент мастера был обновлен с другого устройства во время набора текста',
              })
              this.isUpdateContent = true
              MentorExercisesModule.setHashTask(data.hash)
              this.tempData = {
                content, // запоминаем временный контент
                dateAt: data.created_at,
              }
              return
            }

            if (!this.isActive && this.hash !== data.hash) {
              Sentry.addBreadcrumb({
                category: 'message',
                level: 'info',
                message: 'Обновление контента по сокетам',
              })
              this.updateContentOnSocket(content, data)
              return
            }

            if (this.hash !== data.hash && this.hash !== data.previous_hash) {
              Sentry.addBreadcrumb({
                category: 'message',
                level: 'info',
                message: 'Данные устарели',
              })
              this.showModalError('socket')
            }
          }

          // if (this.isRelevantContent) {
          //   if ((!this.isFocusedWindow || !(this.isFocusedEditor || this.isActive)) && this.hash !== data.hash) {
          //     this.updateContentOnSocket(content, data)
          //   } else if ((!this.isFocusedWindow || !(this.isFocusedEditor || this.isActive)) && this.hash !== data.hash && this.hash !== data.previous_hash) {
          //     this.showModalError(/*'socket'*/)
          //   }
          // }
        }
      })
      this.subscriptionDraft.subscribe()

      this.subscriptionTaskStatusUpdate = this.$centrifuge.newSubscription(`task.${this.selfID}.${this.task.uuid}.${this.master.user.id}`)
      this.subscriptionTaskStatusUpdate.on('publication', ({ data }: any) => {
        if (!this.timestampLossInternet || (this.timestampLossInternet && this.timestampLossInternet < data.task.data_timestamp))
          this.$emit('updateStatusTask', camelCaseKeys(data.task, { deep: true }))
      })
      this.subscriptionTaskStatusUpdate.subscribe()
    }
  }

  private destroyed() {
    MentorExercisesModule.setCurrentMasterID(null)
    window.removeEventListener('online', this.connect)
    window.removeEventListener('offline', this.disconnect)
    window.removeEventListener('pagehide', this.handlePageHide)
    document.removeEventListener('visibilitychange', this.handlePageHide)
    window.removeEventListener('focus', this.handleWindowFocus)
    window.removeEventListener('blur', this.handleWindowBlur)
    window.removeEventListener('touchstart', this.resetTimer)
    window.removeEventListener('touchmove', this.resetTimer)
    window.removeEventListener('keydown', this.resetTimer)
    window.removeEventListener('mousemove', this.resetTimer)
    window.removeEventListener('scroll', this.resetTimer)
    window.removeEventListener('click', this.resetTimer)
    if (this.subscriptionDraft) {
      this.subscriptionDraft.unsubscribe()
      this.$centrifuge.removeSubscription(this.subscriptionDraft)
    }
    if (this.subscriptionTaskStatusUpdate) {
      this.subscriptionTaskStatusUpdate.unsubscribe()
      this.$centrifuge.removeSubscription(this.subscriptionTaskStatusUpdate)
    }
  }

  private resetTimer() {
    this.isActive = true
    clearTimeout(this.timer)
    this.timer = setTimeout(this.userInactive, timeLimit)
    if (!this.isOnline && navigator.onLine) {
      this.isOnline = true
      this.connect()
    }
  }

  private userInactive() {
    this.isActive = false
  }

  private handleWindowFocus() {
    this.isFocusedWindow = true
    if (!this.isOnline && navigator.onLine) {
      this.isOnline = true
      this.connect()
    }
  }

  private handleWindowBlur() {
    this.isFocusedWindow = false
  }

  private openUpdateContent() {
    this.confirm.open(
      'Обновление редактора',
      `Данные редактора были изменены с другого устройства ${formatDate(this.tempData.dateAt, 'd MMMM yyyy в HH:mm', this.isLocalTimezone)}. Вы можете обновить их или сохранить текущие.`,
      {
        buttonCancelText: 'Сохранить текущее',
        buttonConfirmText: 'Обновить',
        hideDefaultClose: false,
        messageNoMargin: true,
        persistent: true,
        skin: 'secondary',
      },
    )
      .then(() => {
        this.isUpdateContent = false
        const tempContent = this.tempData.content
        this.lastContentValue = tempContent
        this.content = tempContent
        MentorExercisesModule.setContentTask(JSON.stringify(tempContent))
        this.$bus.$emit('tiptapSetContent', tempContent)
        this.tempData = {
          content: '',
          dateAt: '',
        }
      })
      .catch(() => {
        this.isUpdateContent = false
        this.saveDraftTask(this.content)
      })
  }

  private connect() {
    this.$centrifuge.on('connect', this.connectSocket)
  }

  private disconnect() {
    this.$centrifuge.off('connect', this.connectSocket)
    this.timestampLossInternet = Number(Date.now().toString().slice(0, -3))
    this.isOnline = false
    this.notifyInfo('Потеряно соединение с интернетом')
  }

  private connectSocket() {
    this.notifyInfo('Соединение с интернетом восстановлено')
    this.isOnline = true
  }

  private showModalError(from: 'save' | 'socket') {
    Sentry.captureMessage(`Данные пользователя (наставника) устарели по причине: ${from === 'socket' ? 'обновление сокетов' : 'отправка сохранения'}`)
    this.isRelevantContent = false
    this.confirm.open(
      'Данные устарели',
      'Данные редактора устарели, обновите страницу.',
      {
        buttonCancelVisible: false,
        buttonConfirmText: 'Обновить',
      },
    )
      .then(() => window.location.reload())
      .catch(() => {return})
  }

  private updateHashDraft() {
    MentorExercisesModule.fetchDraftTask({
      masterId: this.master.user.id,
      taskUuid: this.task.uuid,
    })
  }

  private saveDraft(content: string) {
    return new Promise((resolve, reject) => {
      MentorExercisesModule.saveDraftTask({
        body: {
          content: JSON.stringify(content),
          deviceHash: this.deviceHash,
          previousHash: this.task.draftHash || null,
          questions: [],
        },
        masterId: this.master.user.id,
        taskUuid: this.task.uuid,
      })
        .then((response) => {
          this.lastContentValue = content
          this.isSave = true
          this.draftSaveAt = response.data.expireAt
          this.hideIsSave()
          resolve(response.data)
        })
        .catch((err) => {
          if (err.code === 'ECONNABORTED') {
            Sentry.addBreadcrumb({
              category: 'message',
              data: {
                masterID: this.master.user.id,
                taskUUID: this.task.uuid,
              },
              level: 'info',
              message: 'Данные по запросу',
            })
            Sentry.captureMessage('Запрос отменен по таймауту')
            this.notifyError('Изменения не сохранились. Продолжайте работу, система повторит попытку сохранения данных.')
            this.updateHashDraft()
          } else {
            if (err && err.response && err.response.status === 409) {
              this.showModalError('save')
              err.response ?
                this.notifyError(err.response.data.message) :
                this.notifyError('Содержимое редактора изменено с другого устройства или вкладки.')
            } else {
              // .captureMessage(`При отправке драфта (наставник) произошла ошибка: ${err}`)
              this.notifyError(err)
            }
          }
          reject(err)
        })
    })
  }

  private async saveDraftTask(content: string) {
    if (content !== '' && content !== '<p></p>' && !this.isLoadingContent) {
      this.isLoadingContent = true
      this.isSave = false
      await this.saveDraft(content)
        .then((response: any) => {
          if (JSON.parse(response.content) !== this.content) {
            this.isLoadingContent = false
            this.saveDraftTask(this.content)
          } else {
            this.isLoadingContent = false
          }
        })
        .catch(() => {
          this.isLoadingContent = false
        })
    }
  }

  private handlePageHide({ type }: Event) {
    if (type === 'visibilitychange' && document.visibilityState === 'visible') {
      this.terminatingEventSent = false
    }

    if (this.lastContentValue !== this.content) {
      if (this.terminatingEventSent) return
      if (type === 'pagehide') {
        this.terminatingEventSent = true
        if (this.isRelevantContent)
          this.saveDraftTask(this.content)
        return
      }
      if (type === 'visibilitychange' && document.visibilityState === 'hidden') {
        this.terminatingEventSent = true
        if (this.isRelevantContent)
          this.saveDraftTask(this.content)
      }
    }
  }

  private hideIsSave() {
    setTimeout(() => {
      this.isSave = false
    }, 2000)
  }

  @Debounce(2000)
  @Watch('content')
  private watchContent(value: string) {
    if (this.lastContentValue !== value && !this.terminatingEventSent && !this.isUpdateContent) {
      if (!this.isLoadingContent)
        this.saveDraftTask(value)
    } else if (this.isUpdateContent) {
      this.openUpdateContent()
    }
  }
}
