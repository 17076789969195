import { render, staticRenderFns } from "./MentorSpeakingTask.vue?vue&type=template&id=18f75edc&scoped=true&lang=pug&"
import script from "./MentorSpeakingTask.vue?vue&type=script&lang=ts&"
export * from "./MentorSpeakingTask.vue?vue&type=script&lang=ts&"
import style0 from "./MentorSpeakingTask.vue?vue&type=style&index=0&id=18f75edc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18f75edc",
  null
  
)

export default component.exports