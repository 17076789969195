








































































































import { Bind, Debounce } from 'lodash-decorators'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator'
import { v4 as uuid } from 'uuid'
import camelCaseKeys from 'camelcase-keys'
import * as Sentry from '@sentry/vue'

import Confirmation from '@/components/modals/Confirmation.vue'
import FilesList from '@/components/FilesList.vue'
import TextAreaInput from '@/components/_uikit/controls/TextAreaInput.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import UploadInput from '@/components/_uikit/controls/UploadInput.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import MentorExercisesModule from '@/store/modules/mentor/exercises'
import {
  CourseType,
  EducationLargeTaskResource,
  EducationRateMasterData,
  ExerciseStatus,
  IEditorData,
  MediaResource, TaskRateDraftResource, TaskType,
} from '@/store/types'
import ButtonTextIcon from '@/components/_uikit/buttons/ButtonTextIcon.vue'
import { formatDate } from '@/utils/functions'
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    ButtonTextIcon,
    Confirmation,
    FilesList,
    TextAreaInput,
    TextInput,
    UploadInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class MentorRateTaskForm extends Mixins(NotifyMixin, PermissionsMixin) {
  @Ref() confirm!: Confirmation
  @Ref() confirmChecked!: Confirmation

  @Prop({ required: true })
  private task!: EducationLargeTaskResource

  @Prop({ required: true })
  private groupId!: number

  @Prop({ default: null })
  private exerciseCourseType!: CourseType

  @Prop({ default: null })
  private content!: IEditorData

  @Prop({ default: null })
  private subscriptionDraft!: any

  private get groupID () {
    return +this.$route.params.groupID
  }

  private get exerciseUUID () {
    return this.$route.params.exerciseUUID
  }

  private get masterID () {
    return +this.$route.params.masterID
  }

  private get hash() {
    return MentorExercisesModule.task?.draftHash
  }

  private get formString() {
    return JSON.stringify({
      answer: this.form.answer,
      comment: this.form.comment,
      points: this.form.points,
    })
  }

  // флаг отправки драфта проверки
  private isSavingRateDraft = false

  // хеш устройства пользователя
  private deviceHash = uuid()

  // хэш драфта проверки
  private hashRateDraft: string | null = null

  private showForm = false
  private form: EducationRateMasterData = {
    answer: '',
    comment: '',
    draftHash: this.task.draftHash || null,
    mediaIds: [],
    points: '',
    sendKey: this.task.hasKey,
  }
  private files: MediaResource[] = []

  // первоначальная загрузка данных
  private isLoadingData = false

  // timestamp последней отправки автосохранения
  // нужно для отслеживания активности пользователя
  private lastSaveTS = 0

  // Note: данный геттер нужен для того, чтобы мы могли разделить существующий функционал между
  // Default и Special группами
  private get isSpecialGroup() {
    return this.exerciseCourseType === CourseType.SPECIAL
  }

  private get isPractice () {
    return this.task.type.value === TaskType.PRACTICE
  }

  private get isCreative () {
    return this.task.type.value === TaskType.CREATIVE
  }

  private get isStatusOnCheck () {
    return this.task.status.value === ExerciseStatus.ON_CHECK
  }

  private get isStatusChecked () {
    return this.task.status.value === ExerciseStatus.CHECKED
  }

  private get showOnlyRateForm () {
    return this.isStatusOnCheck && !this.task.isDeadlineFailed || this.isStatusChecked && !this.task.isDeadlineFailed
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private mounted() {
    MentorExercisesModule.fetchDraftRateTask({
      masterID: this.masterID,
      taskUUID: this.task.uuid,
    })
      .then(response => {
        this.hashRateDraft = response.hash
        if (this.isStatusChecked) {
          this.form = {
            answer: response.answer ?? this.task.managerAnnotation ?? '',
            comment: response.comment ?? this.task.comment ?? '',
            draftHash: this.task.draftHash || null,
            mediaIds: this.task.media.map(item => item.id),
            points: response.points ?? this.task.points.toString(),
            sendKey: this.task.hasKey,
          }
          this.files.push(...this.task.media)
        } else if (this.isStatusOnCheck) {
          this.form = {
            ...this.form,
            answer: response.answer ?? this.task.managerAnnotation ?? '',
            comment: response.comment ?? this.task.comment ?? '',
            points: response.points ?? this.task.points,
          }
        }
      })
      .finally(() => {
        this.isLoadingData = true
      })
  }

  private cancelCheck() {
    MentorExercisesModule.removeExerciseFromWork({
      exerciseUuid: this.exerciseUUID,
      masterGroupId: this.groupID,
      masterId: this.masterID,
    })
      .then(() => {
        this.notifyInfo('Проверка дз отменена')
        this.$router.push({
          name: 'manager.education.exercises.quickStart',
        })
      })
      .catch(this.notifyError)
  }

  private handleUploadFile (response: MediaResource) {
    this.files.push(response)
    this.form.mediaIds.push(response.id)
  }

  private handleDeleteFile (id: number) {
    this.files = this.files.filter((file: MediaResource) => file.id !== id)
    this.form.mediaIds = this.form.mediaIds.filter((item: number) => item !== id)
  }

  private handleSendKey () {
    MentorExercisesModule.sendKey({
      masterGroupID: this.groupId,
      masterID: this.masterID,
      taskUUID: this.task.uuid,
    })
      .then(() => {
        this.notifySuccess('Ключ отправлен. Мастер сможет найти его на вкладке "Сообщения"')
        this.$bus.$emit('rate-master-task', false)
      })
      .catch(this.notifyError)
  }

  private confirmSendKey () {
    this.confirm.open(
      'Отправка ключа',
      '<p>Вы действительно хотите отправить <span class="secondary--text font-weight-semibold">ключ с ответами</span> мастеру? После отправки проверка задания завершится, а оценка за домашнее задание будет равна <span class="secondary--text font-weight-semibold">0 баллов</span>.</p><p>Данное действие невозможно будет отменить и проверить домашнее задание наставником.</p>',
      {
        buttonConfirmText: 'Отправить',
        skin: 'secondary',
      },
    )
      .then(this.handleSendKey)
      .catch(() => {return})
  }

  private confirmClose () {
    this.confirmChecked.open(
      'Завершение проверки',
      'Вы уверены, что хотите завершить проверку домашнего задания?',
      {
        buttonConfirmText: 'Завершить',
      },
    )
      .then(this.handleClose)
  }

  @Debounce(300)
  @Bind
  private handleClose () {
    MentorExercisesModule.completeMasterTask({
      masterGroupID: this.groupId,
      masterID: this.masterID,
      taskUUID: this.task.uuid,
    })
      .then(() => {
        this.notifySuccess('Проверка завершена')
        this.$bus.$emit('close-master-task', false)
        this.$bus.$emit('rate-master-task', false)
      })
      .catch(this.notifyError)
  }

  @Bind
  @Debounce(300)
  private handleSubmit () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          MentorExercisesModule.rateMasterTask({
            masterGroupID: this.groupId,
            masterID: this.masterID,
            params: {
              ...this.form,
              content: this.isPractice ? JSON.stringify(this.content) : '',
              draftHash: this.hash || null,
            },
            taskUUID: this.task.uuid,
          })
            .then(() => {
              this.notifySuccess('Оценка выставлена')
              this.showForm = false
              this.$bus.$emit('rate-master-task', false)
            })
            .catch(this.notifyError)
        }
      })
  }

  private async saveDraftRate(updateHash?: string | null) {
    this.isSavingRateDraft = true
    this.lastSaveTS = Date.now()
    await this.saveDraftRateTask(updateHash)
      .then((response: TaskRateDraftResource) => {
        this.isSavingRateDraft = false
        if (this.formString !== JSON.stringify({
          answer: response.answer,
          comment: response.comment,
          points: response.points,
        })) {
          this.saveDraftRate()
        }
      })
      .catch(() => {
        this.isSavingRateDraft = false
      })
  }

  private saveDraftRateTask(updateHash?: string | null): Promise<TaskRateDraftResource> {
    return new Promise((resolve, reject) => {
      MentorExercisesModule.saveDraftRateTask({
        body: {
          answer: this.form.answer,
          comment: this.form.comment,
          deviceHash: this.deviceHash,
          previousHash: updateHash === undefined ? this.hashRateDraft : updateHash,
          points: this.form.points,
        },
        masterID: this.masterID,
        taskUUID: this.task.uuid,
      })
        .then(response => {
          this.hashRateDraft = response.hash
          resolve(response)
        })
        .catch(err => {
          if (err && err.response && err.response.status === 409) {
            this.openUpdateRate('save')
          }
          reject(err)
        })
    })
  }

  // Слушатель изменения формы
  // нужен т.к. на вотчер нельзя повесить debounce (он там не работает)
  @Bind
  @Debounce(500)
  private handleWatchFormString() {
    this.saveDraftRate()
  }

  private updateFormOnSocket(data: TaskRateDraftResource) {
    this.hashRateDraft = data.hash
    this.isLoadingData = false
    this.form = {
      ...this.form,
      answer: data.answer ?? '',
      comment: data.comment ?? '',
      points: data.points ?? '',
    }
    setTimeout(() => {
      this.isLoadingData = true
    }, 10)
  }

  private sendSentryLog(from: 'save' | 'socket') {
    Sentry.captureMessage(`Неактульные данные в форме проверки дз: ${from === 'socket' ? 'обновление по сокету' : 'обновление по запросу'}`)
  }

  private openUpdateRate(from: 'save' | 'socket') {
    MentorExercisesModule.fetchDraftRateTask({
      masterID: this.masterID,
      taskUUID: this.task.uuid,
    })
      .then((response: TaskRateDraftResource) => {
        this.confirm.open(
          'Обновление проверки',
          `Данные формы проверки изменены с другого устройства ${formatDate(response.createdAt, 'd MMMM yyyy в HH:mm', this.isLocalTimezone)}. Вы можете обновить их или сохранить текущие.`,
          {
            buttonCancelText: 'Сохранить текущее',
            buttonConfirmText: 'Обновить',
            hideDefaultClose: false,
            messageNoMargin: true,
            persistent: true,
            skin: 'secondary',
          },
        )
          .then(() => {
            Sentry.addBreadcrumb({
              category: 'message',
              level: 'info',
              message: 'Пользователь подтянул данные с другого устройства',
            })
            this.updateFormOnSocket(response)
          })
          .catch(() => {
            Sentry.addBreadcrumb({
              category: 'message',
              level: 'info',
              message: 'Пользователь оставил данные с текущего устройства',
            })
            this.saveDraftRate(response.hash)
          })
          .finally(() => {
            this.sendSentryLog(from)
          })
      })
  }

  @Watch('formString')
  private watchForm() {
    if (this.isLoadingData && !this.isSavingRateDraft) {
      this.handleWatchFormString()
    }
  }

  @Watch('task')
  private watchTask() {
    this.isLoadingData = false
    this.isSavingRateDraft = true
    MentorExercisesModule.fetchDraftRateTask({
      masterID: this.masterID,
      taskUUID: this.task.uuid,
    })
      .then(response => {
        this.hashRateDraft = response.hash
        if (this.isStatusChecked) {
          this.form = {
            answer: response.answer ?? this.task.managerAnnotation ?? '',
            comment: response.comment ?? this.task.comment ?? '',
            draftHash: this.task.draftHash || null,
            mediaIds: this.task.media.map(item => item.id),
            points: response.points ?? this.task.points,
            sendKey: this.task.hasKey,
          }
          this.files.push(...this.task.media)
        } else if (this.isStatusOnCheck) {
          this.form = {
            ...this.form,
            answer: response.answer ?? this.task.managerAnnotation ?? '',
            comment: response.comment ?? this.task.comment ?? '',
            points: response.points ?? this.task.points,
          }
        }
      })
      .finally(() => {
        this.isLoadingData = true
        this.isSavingRateDraft = false
      })
  }

  @Watch('subscriptionDraft')
  private watchSubscriptionDraft(value: any) {
    if (value) {
      this.subscriptionDraft.on('publication', ({ data }: any) => {
        const form = camelCaseKeys(data, { deep: true })
        if (form.event === 'App\\Events\\Broadcast\\TaskRateDraftStoredEvent') {
          if (this.deviceHash !== form.deviceHash) {
            if (Date.now() - this.lastSaveTS > 5000) {
              this.updateFormOnSocket(form)
            } else if (this.hashRateDraft !== form.hash && this.hashRateDraft !== form.previousHash) {
              this.openUpdateRate('socket')
            }
          }
        }
      })
    }
  }
}
