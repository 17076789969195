




















import { Component, Mixins, Prop } from 'vue-property-decorator'

// components
import MentorRateTaskForm from '@/components/views/exercise/mentor/MentorRateTaskForm.vue'
import MentorSpeakingQuestionsView from '@/components/views/exercise/mentor/MentorSpeakingQuestionsView.vue'
import TaskEmpty from '@/components/TaskEmpty.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import {
  CourseType,
  EducationLargeTaskResource,
  ExerciseStatus,
  MasterLargeResource,
} from '@/store/types'

@Component({
  components: {
    MentorRateTaskForm,
    MentorSpeakingQuestionsView,
    TaskEmpty,
  },
})
export default class MentorSpeakingTask extends Mixins(NotifyMixin) {
  @Prop({ required: true })
  private task!: EducationLargeTaskResource

  @Prop({ required: true })
  private groupId!: number

  @Prop({ default: null })
  private master!: MasterLargeResource

  @Prop({ default: false })
  private readonly!: boolean

  @Prop({ default: null })
  private exerciseCourseType!: CourseType

  private exerciseURL = `${this.groupId}/${this.$route.params.exerciseUUID}`
  private masterKey = `${this.task.uuid}-${this.master.user.id}`

  private get isStatusWait () {
    return this.task.exerciseStatus?.value === ExerciseStatus.WAIT || this.task.exerciseStatus?.value === ExerciseStatus.IN_PROCESS
  }

  private get isStatusChecked () {
    return this.task.exerciseStatus?.value === ExerciseStatus.CHECKED || this.task.exerciseStatus?.value === ExerciseStatus.ON_CHECK
  }
}
